(function () {
  'use strict';

  /* eslint-plugin-disable angular/johnpapa */
  // set cookie containing global id so that if customer signs out it will be
  // available to add to the non-order survey
  setGlobalIdCookie();

  function setGlobalIdCookie() {
    if (!document.cookie.match(/gi=(\d+)/)) {
      document.cookie = 'gi=' + PageClientData.Id + ';domain: .melaleuca.com;path=/';
    }
  }

  (function () {
    checkIfSurvey();

    function setShowSurveyCookie() {
      document.cookie = 'showSurvey=true;domain: .melaleuca.com;path=/';
    }

    function getGlobalIdCookie() {
      var gi = checkForGlobalIdCookie();

      if (gi) {
        return gi[1];
      } else {
        return 'noID';
      }
    }

    function setHasSeenSurveyCookie() {
      document.cookie = 'hasSeenSurvey=true;expires=Tue, 01 Jan 2030 07:00:00 GMT;domain: .melaleuca.com;path=/';
    }

    function checkForHasSeenSurveyCookie() {
      return /hasSeenSurvey=true/.test(document.cookie);
    }

    function checkForGlobalIdCookie() {
      return document.cookie.match(/gi=(\d+)/);
    }

    function checkForShowSurveyCookie() {
      return document.cookie.match(/showSurvey=true/);
    }

    function removeShowSurveyCookie() {
      document.cookie = 'showSurvey=true;expires=Thu, 01 Jan 1970 00:00:01 GMT;domain: .melaleuca.com;path=/';
    }

    function addQueryToSurveyPopup(n) {
      var gi = getGlobalIdCookie();

      if ($('.smcx-btn-primary').length) {
        $('.smcx-btn-primary').attr('href', $('.smcx-btn-primary').attr('href') + '?gl=' + gi + '&cu=' + PageClientData.UICulture); // set this cookie so they don't see a survey popup again

        setHasSeenSurveyCookie();
      } else if (n > 20) {
        return;
      } else {
        setTimeout(function () {
          addQueryToSurveyPopup(n + 1);
        }, 500);
      }
    }

    function checkIfSurvey() {
      if (!checkForHasSeenSurveyCookie()) {
        if (checkForShowSurveyCookie()) {
          // customer has just left order receipt page
          // fire code to launch survey if on
          webm_sv.post_order();
          addQueryToSurveyPopup(1);
          removeShowSurveyCookie();
        } else if (/myaccount\/orderdetails|myaccount\/orderhistory\/orderdetails/i.test(location.pathname) && isReceiptDateToday()) {
          // customer is on order receipt page
          setShowSurveyCookie();
          setTimeout(function () {
            webm_sv.post_order();
            addQueryToSurveyPopup(1);
            removeShowSurveyCookie();
          }, webm_sv.timeBeforeSurvey);
        } else if (/^\/Home$/i.test(location.pathname) && /signedOut=true/i.test(location.search)) {
          // customer has just signed out
          webm_sv.non_order();
          addQueryToSurveyPopup(1);
        }
      }
    }

    function isReceiptDateToday() {
      var temp;

      if ($('#ReceiptOrderDate').length) {
        temp = $.makeArray($('#ReceiptOrderDate'));
      } else if ($('.orderDetailsValues').length) {
        temp = $.makeArray($('.orderDetailsValues')).filter(function (a, i) {
          if (i === 2) return a;
        });
      } else {
        temp = $.makeArray($('.detail-data')).filter(function (o) {
          return /\d+\/\d+\/\d+/i.test(o.innerText);
        });
      }

      var receiptDate = temp.map(function (a) {
        return a.textContent.match(/(\d{2,4})([\u3131-\uD79D]|\/)\s?(\d{1,2})([\u3131-\uD79D]|\/)\s?(\d{2,4})/);
      }).map(function (a) {
        return a.filter(function (b, i) {
          if (i % 2 !== 0) return a;
        });
      }).reduce(function (a, c) {
        return a.concat(c);
      }, []);
      var receiptDateF = formatDate(addZero(receiptDate));
      var todaysDate = getDateWithUTCOffset(8);
      var todaysDateFormatted = (todaysDate.getMonth() + 1 + '/' + todaysDate.getDate() + '/' + todaysDate.getFullYear()).replace(/(\b\d\b)/g, '0$1');
      return todaysDateFormatted == receiptDateF;
    }

    function addZero(arr) {
      return arr.map(function (a) {
        if (a.length === 1) {
          return '0' + a;
        } else {
          return a;
        }
      });
    }

    function formatDate(arr) {
      var ctry = getCountry();

      switch (ctry) {
        case 'kr':
          return arr[1] + '/' + arr[2] + '/' + arr[0];

        case 'ph':
        case 'hk':
          return arr[0] + '/' + arr[1] + '/' + arr[2];

        case 'my':
          return arr[1] + '/' + arr[0] + '/' + arr[2];

        case 'cn':
          return arr[1] + '/' + arr[2] + '/' + arr[0];
      }
    }

    function getCountry() {
      var host = location.hostname;
      var m = host.match(/kr|tw|hk|my|malaysia|cn|ph/);

      if (m) {
        switch (m[0]) {
          case 'kr':
            return 'kr';

          case 'tw':
          case 'hk':
            return 'hk';

          case 'my':
          case 'malaysia':
            return 'my';

          case 'cn':
            return 'cn';

          case 'ph':
            return 'ph';

          default:
            return null;
        }
      } else {
        return null;
      }
    }

    function getDateWithUTCOffset(inputTzOffset) {
      var now = new Date(); // get the current time

      var currentTzOffset = -now.getTimezoneOffset() / 60; // in hours, i.e. -4 in NY

      var deltaTzOffset = inputTzOffset - currentTzOffset; // timezone diff

      var nowTimestamp = now.getTime(); // get the number of milliseconds since unix epoch

      var deltaTzOffsetMilli = deltaTzOffset * 1000 * 60 * 60; // convert hours to milliseconds (tzOffsetMilli*1000*60*60)

      var outputDate = new Date(nowTimestamp + deltaTzOffsetMilli); // your new Date object with the timezone offset applied.

      return outputDate;
    }
  })();

}());
